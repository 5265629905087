import $ from 'jquery';
import 'bootstrap';
import Cookies from 'js-cookie'

import Swiper, {
    Navigation,
    Pagination
} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
Swiper.use([Navigation, Pagination]);

import Rellax from 'rellax/rellax.min';

import AOS from 'aos';

import 'animate.css';

const animateCSS = (element, animation, prefix = 'animate__') =>
    // We create a Promise and return it
    new Promise((resolve, reject) => {
        const animationName = `${prefix}${animation}`;
        const node = document.querySelector(element);

        node.classList.add(`${prefix}animated`, animationName);

        // When the animation ends, we clean the classes and resolve the Promise
        function handleAnimationEnd() {
            node.classList.remove(`${prefix}animated`, animationName);
            resolve('Animation ended');
        }

        node.addEventListener('animationend', handleAnimationEnd, {
            once: true
        });
    });

const general = {
    "mqBreakpoints": {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200
    },

    handleSwiper: function() {
        var HomeSwiper = new Swiper('.swiper-home', {
            direction: 'horizontal',
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            slidesPerView: 1,
        });

        var TrustSwiper = new Swiper('.swiper-trust', {
            direction: 'horizontal',
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            disableAutoResize: false,
            slidesPerView: 1,
            breakpoints: {
                920: {
                    direction: 'vertical',
                    autoHeight: true,
                }
            }
        });
    },

    navbarMobile: function() {
        var burgerBtn = document.getElementById('js-mobile-burgerBtn');
        var body = document.getElementById('body');
        var main = document.getElementById('main');
        var windowPos = null;

        if (burgerBtn !== null) {
            burgerBtn.addEventListener('click', function() {
                body.classList.toggle('js-mobile-navigation');
                if ($(this).attr('aria-expanded') === 'false') {
                    $('#main main').css('transform', 'translateY(0)');
                    $('[data-aos]').removeClass('active');

                    body.classList.toggle('js-mobile-transition');

                    setTimeout(function() {
                        body.classList.toggle('js-mobile-transition');
                        document.documentElement.style.scrollBehavior = 'auto';
                        window.scrollTo(0, parseInt(windowPos));
                        document.documentElement.style.scrollBehavior = 'smooth';
                        $('.nav-item.--parent').removeClass('hover')
                    }, 10);

                } else {
                    if (!windowPos) return;
                    $('#main main').css('transform', 'translateY(-' + windowPos.toString() + 'px)');
                    $('[data-aos]').addClass('active');
                }
            }, false);
        }

        if (main !== null) { 
            main.addEventListener('click', function() {
                main.classList.add('active');
                body.classList.add('main');
                body.classList.remove('navigation');    
            }, false); 
        }

        $(function() {
            var windowPosY = 0;
            $(document).scroll(function() {
                const $nav = $("header .navbar");
                const $header = $("header");
                const $headerNav = $(".navbar-collapse");
                $header.toggleClass('-minified', $(this).scrollTop() > $nav.height());

                $header.toggleClass('--down', $(this).scrollTop() > windowPosY);
                windowPosY = $(this).scrollTop()

                if (!$('body').hasClass('js-mobile-navigation')) {
                    windowPos = window.pageYOffset;
                }
            });
        });

        // Behaviour parent
        $('body')
            .on('click', '.nav-item.--parent', function() {
                if ($('body').hasClass('js-mobile-navigation')) {
                    $(this).toggleClass('hover');
                }
            })
            .on('mouseenter', '.nav-item.--parent', function() {
                if (!$('body').hasClass('js-mobile-navigation')) {
                    $(this).toggleClass('hover');
                }
            })
            .on('mouseleave', '.nav-item.--parent', function() {
                if (!$('body').hasClass('js-mobile-navigation')) {
                    $(this).toggleClass('hover');
                }
            });
    },
    getCurrentCountryCode: function() {},
    handleMap: function() {
        let haveOffices = $('#js-offices-coordinates');
        if (!haveOffices.length) return;
        let offices = officesObject
        try {
            let script = document.createElement('script');
            let map;
            const key = 'AIzaSyDwb5jxEQ5Lbt_kxHwfD9cdiFYgVz9WGfA';
            script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&callback=initMap`;
            script.defer = true;
            script.async = true;

            window.initMap = function() {
                if (!document.getElementById("map")) return;
                const centerMap = {
                    // lat: 48.255885,
                    lat: 41.3021511,
                    // lng: 26.6926095
                    lng: 60.0849743,
                };
                // The map, centered at Uluru
                map = new google.maps.Map(document.getElementById("map"), {
                    zoom: 3.1,
                    center: centerMap,
                    disableDefaultUI: true
                });



                var marker = [];
                var infoWindow = new google.maps.InfoWindow();
                Object.keys(offices).forEach(($key, i) => {
                    marker[$key] = new google.maps.Marker({
                        position: new google.maps.LatLng(offices[$key].lat, offices[$key].lon),
                        animation: google.maps.Animation.DROP,
                        map: map,
                        icon: '/static/img/circle-white.svg',
                        key: $key,
                        lat: offices[$key].lat,
                        lon: offices[$key].lon,
                        city: offices[$key].city,
                        name: offices[$key].name
                    });
                    marker[$key]
                        .addListener("click", (function(m, i) {
                            const html =
                                `
                            <div class="map-cart__container">
                                <h5 class="map-cart__name">
                                    ${offices[$key].name}
                                </h5>
                                <p class="map-cart__address">
                                    ${offices[$key].address}
                                </p>
                                <p class="map-cart__address">
                                    ${offices[$key].postalcode != 'None' ? offices[$key].postalcode : ''} ${offices[$key].city}
                                </p>
                                <h6 class="map-cart__mail">
                                    ${offices[$key].mail}
                                </h6>
                                <a class="map-cart__number ${offices[$key].number ? 'd-block' : 'd-none'}" href="tel:${offices[$key].number}">
                                    ${offices[$key].number}
                                </a>
                                
                                <a class="map-cart__link ${offices[$key].nameRedirect ? 'd-block' : 'd-none'}" href="mailto:${offices[$key].mail}">
                                    ${offices[$key].nameRedirect}
                                </a>
                        
                            </div>
                            `
                            return function() {
                                infoWindow.setOptions({
                                    content: html
                                });
                                infoWindow.open(map, marker[$key]);
                            };
                        })(marker[$key], $key));
                });

                function openFirstLocation() {
                    if (window.innerWidth >= general.mqBreakpoints.xl) {
                        marker.forEach(e => {
                            if (e.name.includes('Luxembourg') || e.city == ('Luxembourg' || 'Bertrange')) {
                                google.maps.event.trigger(e, 'click');
                            }
                        });
                    }
                }
                openFirstLocation()

                const styledMapType = new google.maps.StyledMapType(
                    [{
                            "elementType": "geometry",
                            "stylers": [{
                                "color": "#1d2c4d"
                            }]
                        },
                        {
                            "elementType": "labels",
                            "stylers": [{
                                "visibility": "off"
                            }]
                        },
                        {
                            "elementType": "labels.text.fill",
                            "stylers": [{
                                "color": "#8ec3b9"
                            }]
                        },
                        {
                            "elementType": "labels.text.stroke",
                            "stylers": [{
                                "color": "#1a3646"
                            }]
                        },
                        {
                            "featureType": "administrative.country",
                            "elementType": "geometry.stroke",
                            "stylers": [{
                                "color": "#1859be"
                            }]
                        },
                        {
                            "featureType": "administrative.land_parcel",
                            "stylers": [{
                                "visibility": "off"
                            }]
                        },
                        {
                            "featureType": "administrative.land_parcel",
                            "elementType": "labels.text.fill",
                            "stylers": [{
                                "color": "#64779e"
                            }]
                        },
                        {
                            "featureType": "administrative.neighborhood",
                            "stylers": [{
                                "visibility": "off"
                            }]
                        },
                        {
                            "featureType": "administrative.province",
                            "elementType": "geometry.stroke",
                            "stylers": [{
                                "visibility": "off"
                            }]
                        },
                        {
                            "featureType": "landscape.man_made",
                            "elementType": "geometry.stroke",
                            "stylers": [{
                                "color": "#334e87"
                            }]
                        },
                        {
                            "featureType": "landscape.natural",
                            "elementType": "geometry",
                            "stylers": [{
                                "color": "#4689f1"
                            }]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "geometry",
                            "stylers": [{
                                "color": "#283d6a"
                            }]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels.text",
                            "stylers": [{
                                "visibility": "off"
                            }]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels.text.fill",
                            "stylers": [{
                                "color": "#6f9ba5"
                            }]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels.text.stroke",
                            "stylers": [{
                                "color": "#1d2c4d"
                            }]
                        },
                        {
                            "featureType": "poi.business",
                            "stylers": [{
                                "visibility": "off"
                            }]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry.fill",
                            "stylers": [{
                                    "color": "#023e58"
                                },
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "labels.text.fill",
                            "stylers": [{
                                "color": "#3C7680"
                            }]
                        },
                        {
                            "featureType": "road",
                            "stylers": [{
                                "visibility": "off"
                            }]
                        },
                        {
                            "featureType": "road",
                            "elementType": "geometry",
                            "stylers": [{
                                "color": "#304a7d"
                            }]
                        },
                        {
                            "featureType": "road",
                            "elementType": "labels.icon",
                            "stylers": [{
                                "visibility": "off"
                            }]
                        },
                        {
                            "featureType": "road",
                            "elementType": "labels.text.fill",
                            "stylers": [{
                                "color": "#98a5be"
                            }]
                        },
                        {
                            "featureType": "road",
                            "elementType": "labels.text.stroke",
                            "stylers": [{
                                "color": "#1d2c4d"
                            }]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry",
                            "stylers": [{
                                "color": "#2c6675"
                            }]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry.stroke",
                            "stylers": [{
                                "visibility": "off"
                            }]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "labels.text.fill",
                            "stylers": [{
                                "color": "#b0d5ce"
                            }]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "labels.text.stroke",
                            "stylers": [{
                                "color": "#023e58"
                            }]
                        },
                        {
                            "featureType": "transit",
                            "stylers": [{
                                "visibility": "off"
                            }]
                        },
                        {
                            "featureType": "transit",
                            "elementType": "labels.text.fill",
                            "stylers": [{
                                "color": "#98a5be"
                            }]
                        },
                        {
                            "featureType": "transit",
                            "elementType": "labels.text.stroke",
                            "stylers": [{
                                "color": "#1d2c4d"
                            }]
                        },
                        {
                            "featureType": "transit.line",
                            "elementType": "geometry.fill",
                            "stylers": [{
                                    "color": "#283d6a"
                                },
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.station",
                            "elementType": "geometry",
                            "stylers": [{
                                "visibility": "off"
                            }]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [{
                                "color": "#0d233c"
                            }]
                        },
                        {
                            "featureType": "water",
                            "elementType": "labels.text.fill",
                            "stylers": [{
                                "color": "#4e6d70"
                            }]
                        }
                    ]

                );
                map.mapTypes.set("styled_map", styledMapType);
                map.setMapTypeId("styled_map");
            }

            document.head.appendChild(script);

        } catch (err) { // invalid json
            //return console.log(err);
        }
    },

    selectToUl: function() {
        $("#js-select-transform option").each(function() {
            if (!$(this).is(':selected')) {
                $("#js-ul-transform").append('<li class="lang-switcher__item"><a href="#" class="btn btn-square" data-value="' + this.value + '">' + $(this).html() + '</a></li>');
            }
        });
        $("#js-ul-transform").on("click", "li a", function(e) {
            e.preventDefault();
            $("#js-select-transform").val($(this).attr("data-value"));
            $("#js-select-transform").closest('form').submit();
        });
    },

    filterJobsForMobile: function() {
        if (window.innerWidth <= this.mqBreakpoints['xl']) {
            let rows = $('.workus-top__col');

            if (rows.length) {
                // On compte les cartes de la première colonne pour itérer
                let elementsPerCol1 = $(rows[0]).find('.card');
                let elementsPerCol2 = $(rows[1]).find('.card');
                let elementsPerCol3 = $(rows[2]).find('.card');

                let idx = 0;
                let tmpNode = [];

                while (idx < elementsPerCol1.length) {
                    if (typeof elementsPerCol1[idx] !== 'undefined') {
                        tmpNode.push(elementsPerCol1[idx]);
                    }

                    if (typeof elementsPerCol2[idx] !== 'undefined') {
                        tmpNode.push(elementsPerCol2[idx]);
                    }

                    if (typeof elementsPerCol3[idx] !== 'undefined') {
                        tmpNode.push(elementsPerCol3[idx]);
                    }

                    idx += 1;
                }

                // reset content of all columns
                $(rows[0]).empty();
                $(rows[1]).empty();
                $(rows[2]).empty();

                idx = 0;
                while (idx < tmpNode.length) {
                    rows[0].appendChild(tmpNode[idx]);
                    idx += 1;
                }
            }
        }
    },

    validateName: function(id = 'c_name') {
        var name = $(`#${id}`).val();
        if (name == null || name == "") {
            $("#email-success").hide();
            $(`#${id}`).removeClass("valid");
            $(`#${id}`).addClass("unvalid");
        } else {
            $(`#${id}`).addClass("valid");
            $(`#${id}`).removeClass("unvalid");
        }
    },

    validateMail: function(id = 'c_email') {
        const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const emailValue = $(`#${id}`).val();
        if (!regexEmail.test(emailValue)) {
            $("#email-success").hide()
            $(`#${id}`).addClass("unvalid");
            $(`#${id}`).removeClass("valid");
        } else {
            $(`#${id}`).removeClass("unvalid");
            $(`#${id}`).addClass("valid");
        }
    },

    validatePhone: function(id = 'c_phone') {
        const phoneValue = $(`#${id}`).val();
        const regexPhone = /^\+(?:[0-9] ?){6,14}[0-9]$/;

        if (!regexPhone.test(phoneValue)) {
            $("#email-success").hide()
            $(`#${id}`).addClass("unvalid");
            $(`#${id}`).removeClass("valid");
        } else {
            $(`#${id}`).removeClass("unvalid");
            $(`#${id}`).addClass("valid"); 
        }
    },

    validateMessage: function(id = "c_message") {
        var message = $(`#${id}`).val();
        if (message == null || message == "" || message.length < 9) {
            $("#email-success").hide()
            $(`#${id}`).addClass("unvalid"); 
            $(`#${id}`).removeClass("valid");
        } else {
            $(`#${id}`).removeClass("unvalid");
            $(`#${id}`).addClass("valid");
        }
    },

    validateCaptcha: function() {
        const captcha = grecaptcha.getResponse();
        if (captcha.length > 0) {
            return true;
        } else {
            return false;
        }
    },

    prepareContactForm: function() {
        $("#email-success").hide()
        $('#c_name').keyup(general.validateName('c_name'));
        $('#c_email').keyup(general.validateMail('c_mail'));
        $('#c_phone').keyup(general.validatePhone('c_phone'));
        $('#c_message').keyup(general.validateMessage('c_message'));

        $("#c_send").on('click', function() {
            if (!general.form_valid()) {
                $("#email-error__field").show()
            } else {
                if (general.validateCaptcha()) {
                    $("#email-error__field").hide()
                    $("#email-error__server").hide()
                    $("#email-error__captcha").hide()
                    $.ajax({
                        type: "POST",
                        url: "/ajax/mail",
                        data: $("#contact-form").serialize(),
                        dataType: "text",
                        success: function(data) {
                            $("#email-error__field").hide()
                            $("#email-error__server").hide()
                            $("#email-success").show()
                            grecaptcha.reset();
                        },
                        error: function(data) {
                            $("#email-success").hide()
                            $("#email-error__server").show()
                            grecaptcha.reset();
                        }
                    });
                } else {
                    $("#email-error__captcha").show()
                }
                return false;
            }
        });
    },

    prepareLandingForm: function() {
       
        $("#email-success").hide()
        $('#l_name').keyup(general.validateName("l_name"));
        $('#l_prname').keyup(general.validateName("l_prname"));
        $('#l_company').keyup(general.validateName("l_company"));
        $('#l_email').keyup(general.validateMail('l_email'));
        $('#l_project').keyup(general.validateName('l_project'));  
        
        $("#l-submit").on('click', function() { 
        
            if (!general.form_valid('landing')) {
                $("#errorLandingForm").show()
                
            } else {
                if (general.validateCaptcha()) {
                    
                    $("#errorLandingForm").hide()
                    $("#errorLandingFormCaptcha").hide()
                    $("#errorLandingOther").hide()
 
                    $.ajax({
                        type: "POST",
                        url: "/ajax/maillanding",
                        data: $("#form-landing").serialize(), 
                        dataType: "text",
                        success: function(data) {
                            $("#errorLandingForm").hide()
                            $("#errorLandingOther").hide()
                            grecaptcha.reset();

                            window.location.replace($('#redirectto').val());
                        },
                        error: function(data) {
                            $("#errorLandingOther").show()
                            grecaptcha.reset();  
                        }
                    });
                } else {
                    $("#errorLandingFormCaptcha").show()
                }
                return false;  
            }
        });
    },

    prepareNewsletterForm: function() {
        $('#n_mail').keyup(general.validateMail('n_mail'));
        $("#n_send").on('click', function() {
            if (!general.form_valid('newsletter')) {
                $("#email-error__field").show()
            } else {
                if (general.validateCaptcha()) {
                    $("#email-error__field").hide()
                    $("#email-error__server").hide()
                    $("#email-error__captcha").hide()
                    post_data.email = $('#n_mail').val();
                    $.ajax({
                        type: "POST",
                        url: "/ajax/newsletter",
                        data: post_data,
                        dataType: "text",
                        success: function(data) {
                            if (data == 0) {
                                $("#email-error__already").show()
                            } else {
                                $("#email-error__field").hide()
                                $("#email-error__server").hide()
                                $("#email-success").show()
                            }
                            grecaptcha.reset();
                        },
                        error: function(data) {
                            $("#email-error__server").show();
                            grecaptcha.reset();
                        }
                    });
                } else {
                    $("#email-error__captcha").show()
                }

                return false;
            }
        })
    },

    checkCheckbox: function(id) {
        if ($(`#${id}`).is(":checked")) {
            $(`#${id}`).addClass('valid');
            $(`.${id} > label`).removeClass('bounce');
            return true;
        } else {
            $(`#${id}`).removeClass('valid');
            $(`.${id} > label`).addClass('bounce');
            return false;
        }
    },

    form_valid: function(type = 'contact') {
        if (type == 'landing') {
            this.validateName("l-prname");
            this.validateName("l-name");
            this.validateName("l-company");
            this.validateMail("l-email");
            this.validateName("l-project");

            if ($("#l-prname").hasClass("valid") &&
                $("#l-name").hasClass("valid") &&
                $("#l-company").hasClass("valid") &&
                $("#l-email").hasClass("valid") &&
                $("#l-project").hasClass("valid")) { 
                return true; 
            }
            return false; 
        } else if (type == 'contact') {
            this.validateName();
            this.validateMail();
            this.validatePhone();
            this.validateMessage();

            if ($("#c_name").hasClass("valid") &&
                $("#c_phone").hasClass("valid") &&
                $("#c_email").hasClass("valid") &&
                $("#c_message").hasClass("valid")) {
                return true; 
            }
            return false;
        } else if (type == 'newsletter') {
            this.validateMail('n_mail'); 
            if ($("#n_mail").hasClass("valid")) {
                return true;
            }
            return false;
        }
    },

    removeAutoFill: function() {
        if (navigator.userAgent.toLowerCase().indexOf("chrome") >= 0) {
            $('input, select').on('change focus', function(e) {
                setTimeout(function() {
                    $.each(
                        document.querySelectorAll('*:-webkit-autofill'),
                        function() {
                            var clone = $(this).clone(true, true);
                            $(this).after(clone).remove();
                            updateActions();
                        })
                }, 300)
            }).change();
        }
        var updateActions = function() {}; // method for update input actions
        updateActions(); // start on load and on rebuild
    },

    languageSwitcherHover: function() {
        $('.js-language-switcher')
            .on('mouseenter', function() {
                if (general.mqBreakpoints['lg'] < window.innerWidth) {
                    if (!$(this).find('.dropdown-toggle').hasClass('show')) {
                        $(this).find('.dropdown-toggle').trigger('click');
                    }
                }
            })
            .on('mouseleave', function() {
                if (general.mqBreakpoints['lg'] < window.innerWidth) {
                    if ($(this).find('.dropdown-toggle').hasClass('show')) {
                        $(this).find('.dropdown-toggle').trigger('click');
                    }
                }
            });
    },

    getLangages: function() {
        const urlPath = window.location.pathname.split('/');
        switch (urlPath[1]) {
            case 'de':
                return 'de'
            case 'it':
                return 'it'
            case 'fr':
                return 'fr'
            default:
                return 'en'
        }
    },
    languageBrowserHandler: function() {
        if (typeof Cookies.get('favouriteLanguage') === 'undefined') {
            let userLang = navigator.language || navigator.userLanguage;
            userLang = userLang.substr(0, 2).toLowerCase();
            Cookies.set('favouriteLanguage', 'true', { expires: 1, path: '' })

            if (userLang != 'en') {
                $('#js-select-transform').val(userLang);
                $('#js-select-transform').closest('form').submit();
            }
        }
    }
};

export default general;

(function($) {
    general.languageBrowserHandler();

    $(document).ready(function() {
        AOS.init();
        general.handleSwiper();
        general.navbarMobile();
        general.handleMap();
        general.selectToUl();
        general.filterJobsForMobile();
        general.prepareContactForm();
        general.prepareLandingForm();
        general.prepareNewsletterForm();
        general.removeAutoFill();
        general.languageSwitcherHover();

        var rellax = new Rellax('.rellax');
    });
})($);